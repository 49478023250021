import React, { useState, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import awsconfig from '../aws-exports';
import styles from './ContactUs.module.css';
import { sendContactMessage } from '../graphql/mutations';

// Configure Amplify
Amplify.configure(awsconfig);

const apiConfig = awsconfig.API.GraphQL;

function ContactForm({ formData, onSubmitSuccess, handleChange }) {
  const [submitError, setSubmitError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (!apiConfig.apiKey) {
      console.error('API Key is not configured in aws-exports.js');
    }
    console.log('API Endpoint:', apiConfig.endpoint);
    console.log('API Key:', apiConfig.apiKey ? 'Configured' : 'Not Configured');
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);

    try {
      console.debug('Sending message:', formData);
      console.debug('API Key being used:', apiConfig.apiKey ? 'Configured' : 'Not Configured');
      console.debug('GraphQL Query:', sendContactMessage);

      const response = await fetch(apiConfig.endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiConfig.apiKey
        },
        body: JSON.stringify({
          query: sendContactMessage,
          variables: { input: formData }
        })
      });

      const result = await response.json();
      console.debug('Full GraphQL Response:', JSON.stringify(result, null, 2));

      if (result.data && result.data.sendContactMessage && result.data.sendContactMessage.success) {
        console.debug('Message sent successfully');
        onSubmitSuccess(result.data.sendContactMessage.message);
      } else if (result.errors && result.errors.length > 0) {
        console.error('GraphQL Errors:', JSON.stringify(result.errors, null, 2));
        throw new Error(result.errors[0].message || 'An error occurred while sending the message');
      } else {
        console.error('Unexpected Response Structure:', JSON.stringify(result, null, 2));
        throw new Error('Unexpected response from the server');
      }
    } catch (err) {
      console.error('Detailed Error:', JSON.stringify(err, Object.getOwnPropertyNames(err)));
      setSubmitError(err.message || 'An unexpected error occurred. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <div className={styles.formGroup}>
        <label htmlFor="name_first">First Name:</label>
        <input
          type="text"
          id="name_first"
          name="name_first"
          value={formData.name_first}
          onChange={handleChange}
          required
          className={styles.input}
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="name_last">Last Name:</label>
        <input
          type="text"
          id="name_last"
          name="name_last"
          value={formData.name_last}
          onChange={handleChange}
          required
          className={styles.input}
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          className={styles.input}
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="subject">Subject:</label>
        <input
          type="text"
          id="subject"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          required
          className={styles.input}
        />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="message">Message:</label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
          className={styles.textarea}
        ></textarea>
      </div>
      {submitError && <div className={styles.error}>{submitError}</div>}
      <button type="submit" disabled={isProcessing} className={styles.submitButton}>
        {isProcessing ? 'Sending...' : 'Send'}
      </button>
    </form>
  );
}

function ContactUs() {
  const [formData, setFormData] = useState({
    name_first: '',
    name_last: '',
    email: '',
    subject: '',
    message: ''
  });
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmitSuccess = (message) => {
    setSubmitStatus('success');
    setFormData({ name_first: '', name_last: '', email: '', subject: '', message: '' });
  };

  return (
    <div className={styles.contactContainer}>
      <h2 className={styles.title}>Contact Us</h2>
      <p>Use the form below to email us. You may also reach us directly via messaging us on Instagram or Facebook. We are happy to get back to you as soon as possible!</p>
      <ContactForm
        formData={formData}
        onSubmitSuccess={handleSubmitSuccess}
        handleChange={handleChange}
      />
      {submitStatus === 'success' && (
        <p className={styles.successMessage}>Your message has been sent successfully!</p>
      )}
    </div>
  );
}

export default ContactUs;
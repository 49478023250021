const awsconfig = {
    "aws_project_region": process.env.REACT_APP_AWS_REGION,
    "aws_cognito_region": process.env.REACT_APP_AWS_REGION,
    "aws_user_pools_id": process.env.REACT_APP_USER_POOL_ID,
    "aws_user_pools_web_client_id": process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    "aws_cognito_identity_pool_id": process.env.REACT_APP_IDENTITY_POOL_ID,
    Auth: {
        region: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID
    },
    API: {
        GraphQL: {
            endpoint: process.env.REACT_APP_APPSYNC_ENDPOINT,
            region: process.env.REACT_APP_AWS_REGION,
            defaultAuthMode: 'AMAZON_COGNITO_USER_POOLS',
            apiKey: process.env.REACT_APP_APPSYNC_API_KEY
        }
    }
};

export default awsconfig;
import React from 'react';
import { Link } from 'react-router-dom';
import { getCurrentUser, signOut } from 'aws-amplify/auth';
import styles from './Navigation.module.css';

function Navigation() {
  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    checkUser();
  }, []);

  async function checkUser() {
    try {
      const currentUser = await getCurrentUser();
      setUser(currentUser);
    } catch (err) {
      setUser(null);
    }
  }

  async function handleSignOut() {
    try {
      await signOut();
      setUser(null);
    } catch (error) {
      console.error('Error signing out: ', error);
    }
  }

  return (
    <nav className={styles.nav}>
      <ul className={styles.navList}>
        <li className={styles.navItem}>
          <Link to="/" className={styles.navLink}>Home</Link>
        </li>
        <li className={styles.navItem}>
          <Link to="/register" className={styles.navLink}>Register</Link>
        </li>
        <li className={styles.navItem}>
          <Link to="/contactUs" className={styles.navLink}>Contact Us</Link>
        </li>
        {!user ? (
            <>
              <li className={styles.navItem}>
                <Link to="/signin" className={styles.navLink}>Sign In</Link>
              </li>
              <li className={styles.navItem}>
                <Link to="/signup" className={styles.navLink}>Sign Up</Link>
              </li>
            </>
        ) : (
            <li className={styles.navItem}>
              <button onClick={handleSignOut} className={styles.navLink}>Sign Out</button>
            </li>
        )}
      </ul>
    </nav>
  );
}

export default Navigation;
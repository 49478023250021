import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import styles from './PaymentConfirmation.module.css';
import {confirmRegistration } from "../graphql/mutations";
import awsconfig from "../aws-exports";

const apiConfig = awsconfig.API.GraphQL;

const PaymentConfirmation = () => {
    const location = useLocation();
    const {state} = location;
    const { registration_data } = state;
    const { payment_data } = state;

    console.debug('registration_data:', registration_data);
    console.debug('payment_data:', payment_data);

    if (!state) {
        return (
            <div className={styles.container}>
                <div className={styles.errorMessage}>
                    <h2>Error</h2>
                    <p>No payment information found. Please try registering again.</p>
                </div>
            </div>
        );
    }

    const {email, transactionId, paymentMethod, amount} = payment_data;

    const isSuccess = !!transactionId;

    console.debug('isSuccess:', isSuccess);

    const response = fetch(apiConfig.endpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiConfig.apiKey
        },
        body: JSON.stringify({
            query: confirmRegistration,
            variables: {
                registration_data: registration_data,
                payment_data: {
                    payment_id: transactionId,
                    payment_method: paymentMethod,
                    amount: amount
                }
            }
        })
    });
    response.then((response) => {
        console.debug('response:', response);
    }).catch((error) => {
        console.error('error:', error);
    });

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Payment Confirmation</h1>

            {isSuccess ? (
                <div className={styles.successMessage}>
                    <h2>Success</h2>
                    <p>Your payment has been processed successfully.</p>
                </div>
            ) : (
                <div className={styles.errorMessage}>
                    <h2>Payment Failed</h2>
                    <p>There was an error processing your payment. Please try again.</p>
                </div>
            )}

            <div className={styles.detailsCard}>
                <h2>Payment Details</h2>
                <ul>
                    <li><strong>Email:</strong> {email}</li>
                    {isSuccess && (
                        <>
                            <li><strong>Transaction ID:</strong> {transactionId}</li>
                            <li><strong>Payment Method:</strong> {paymentMethod}</li>
                            <li><strong>Amount:</strong> {amount}</li>
                        </>
                    )}
                </ul>

                <div className={styles.buttonContainer}>
                    <Link to="/" className={styles.link}>
                        <button className={styles.button}>Home</button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default PaymentConfirmation;
import React from 'react';
import Navigation from './Navigation';
import styles from './Layout.module.css';

function Layout({ children }) {
  return (
    <div className={styles.layout}>
      <header className={styles.header}>
        <Navigation />
      </header>
      <main className={styles.main}>{children}</main>
      <footer className={styles.footer}>
        <p>© 2024 Within The Veil</p>
      </footer>
    </div>
  );
}

export default Layout;
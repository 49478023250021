import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import styles from './RegistrationConfirmation.module.css';

function RegistrationConfirmation() {
  const location = useLocation();
  const { registration_id } = location.state || {};

  return (
    <div className={styles.confirmationContainer}>
      <h1 className={styles.title}>Thank You for Registering!</h1>
      <p className={styles.message}>
        Your registration has been successfully processed.
      </p>
      {registration_id && (
        <p className={styles.registrationId}>
          Your Registration ID is: <strong>{registration_id}</strong>
        </p>
      )}
      <p className={styles.instruction}>
        Please keep this ID for your records. You may need it for future reference.
      </p>
      <p>There is just one last step, we need to receive payment and you will be completely registered</p>
      <p>
        <Link to="/checkout" className={styles.homeLink}>
            Pay the Registration Fee
        </Link>
      </p>
      <p>
        <Link to="/" className={styles.homeLink}>
          Return to Home
        </Link>
      </p>
    </div>
  );
}

export default RegistrationConfirmation;
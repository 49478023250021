import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import '@aws-amplify/ui-react/styles.css';

import Layout from './components/Layout';
import Home from './pages/Home';
import Checkout from './pages/Checkout';
import PaymentConfirmation from './pages/PaymentConfirmation';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ConfirmSignUp from "./pages/ConfirmSignUp";
import Register from './pages/Register';
import ContactUs from './pages/ContactUs';
import RegistrationConfirmation from "./pages/RegistrationConfirmation";

// Replace with your own Stripe public key
const stripePromise = loadStripe('pk_live_DRjs2LsWVnFzjlEK886Rb4q0');

// Updated ProtectedRoute component
const ProtectedRoute = ({ children }) => {
  const { authStatus } = useAuthenticator();

  if (authStatus === 'configuring') {
    return <div>Loading...</div>;
  }

  if (authStatus !== 'authenticated') {
    return <Navigate to="/signin" replace />;
  }

  return children;
};

function App() {
  return (
    <Authenticator.Provider>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/confirm-signup" element={<ConfirmSignUp />} />
            <Route path="/contactUs" element={<ContactUs />} />

            {/* Protected routes */}
            <Route path="/checkout" element={
              <ProtectedRoute>
                <Elements stripe={stripePromise}>
                  <Checkout />
                </Elements>
              </ProtectedRoute>
            } />
            <Route path="/paymentConfirmation" element={
              <ProtectedRoute>
                <PaymentConfirmation />
              </ProtectedRoute>
            } />
            <Route path="/confirmation" element={
              <ProtectedRoute>
                <PaymentConfirmation />
              </ProtectedRoute>
            } />
            <Route path="/register" element={
              <ProtectedRoute>
                <Register />
              </ProtectedRoute>
            } />
            <Route path="/registrationConfirmation" element={
              <ProtectedRoute>
                <RegistrationConfirmation />
              </ProtectedRoute>
            } />
          </Routes>
        </Layout>
      </Router>
    </Authenticator.Provider>
  );
}

export default App;
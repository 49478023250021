import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { getCurrentUser, fetchUserAttributes, fetchAuthSession } from 'aws-amplify/auth';
import { v4 as uuidv4 } from 'uuid';
import awsconfig from '../aws-exports';
import styles from './Register.module.css';
import { processRegistration } from "../graphql/mutations";

// Configure Amplify
Amplify.configure(awsconfig);

const apiConfig = awsconfig.API.GraphQL;

function Register() {

  const [formData, setFormData] = useState({
    userId: '',
    email: '',
    name_first: '',
    name_last: '',
    gender: '',
    birthdate: '',
    allergies: '',
    dietary_restrictions: '',
    home_church: '',
    emergency_contact_name: '',
    emergency_contact_phone: '',
    emergency_contact_relationship: '',
    photography_consent: '',
    drug_policy_acknowledged: false,
    medication_policy_acknowledged: false,
    refund_policy_acknowledged: false,
    truthfulness_acknowledged: false,
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [submitError, setSubmitError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserInfo = async () => {
      setIsLoading(true);
      try {
        const currentUser = await getCurrentUser();
        console.info('Current user:', JSON.stringify(currentUser, null, 2));

        if (!currentUser) {
          throw new Error('No user found');
        }

        const userAttributes = await fetchUserAttributes();
        console.log('User attributes:', JSON.stringify(userAttributes, null, 2));

        if (!userAttributes.email) {
          throw new Error('User email not found');
        }

        setFormData(prevData => ({
          ...prevData,
          userId: currentUser.userId,
          email: userAttributes.email,
          name_first: userAttributes.given_name || '',
          name_last: userAttributes.family_name || '',
        }));

      } catch (error) {
        console.error('Error fetching user info:', error);
        setError('Unable to fetch user information. Please try logging in again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserInfo();
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);
    setSubmitError(null);

    // Validate age
    const birthDate = new Date(formData.birthdate);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    if (age < 18) {
      setSubmitError('You must be at least 18 years old to register.');
      setIsProcessing(false);
      return;
    }

    try {
      console.log('Fetching auth session...');
      const { tokens } = await fetchAuthSession();
      const idToken = tokens.idToken.toString();

      if (!idToken) {
        throw new Error('No authentication token available. Please sign in again.');
      }

      console.log('Auth token acquired successfully');

      const registration_id = uuidv4();

      const inputData = {
        registration_id: registration_id,
        cognito_id: formData.userId,
        name_first: formData.name_first,
        name_last: formData.name_last,
        email: formData.email,
        gender: formData.gender,
        birthdate: formData.birthdate,
        allergies: formData.allergies,
        dietary_restrictions: formData.dietary_restrictions,
        home_church: formData.home_church,
        emergency_contact_name: formData.emergency_contact_name,
        emergency_contact_phone: formData.emergency_contact_phone,
        emergency_contact_relationship: formData.emergency_contact_relationship,
        photography_consent: formData.photography_consent,
        drug_policy_acknowledged: formData.drug_policy_acknowledged,
        medication_policy_acknowledged: formData.medication_policy_acknowledged,
        refund_policy_acknowledged: formData.refund_policy_acknowledged,
        truthfulness_acknowledged: formData.truthfulness_acknowledged,
        created_on: new Date().toISOString()
      };

      const response = await fetch(apiConfig.endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiConfig.apiKey
        },
        body: JSON.stringify({
          query: processRegistration,
          variables: { input: inputData }
        })
      });
      console.debug('variables:', inputData);
      console.debug('Response:', response);
      console.debug('Response status:', response.status);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      if (result.data && result.data.processRegistration) {
        console.log('Registration successful');

        // Store registration data in localStorage
        localStorage.setItem('registrationData', JSON.stringify({
          ...formData,
          registration_id
        }));

        // Navigate to the checkout page
        navigate('/checkout', {
          state: { ...inputData }
        });

      } else {
        throw new Error('Unexpected response structure from server');
      }

    } catch (err) {
      console.error('Detailed Error:', err);
      console.error('Error Stack:', err.stack);
      setSubmitError(err.message || 'An unexpected error occurred. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  if (isLoading) {
    return <div className={styles.loading}>Loading user information...</div>;
  }

  if (error) {
    return (
      <div className={styles.error}>
        {error}
        <button onClick={() => navigate('/signin')}>Go to Sign In</button>
      </div>
    );
  }

  return (
    <div className={styles.registerContainer}>
      <h2 className={styles.title}>Register</h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.formGroup}>
          <label htmlFor="userId">User ID:</label>
          <input
              type="text"
              id="userId"
              name="userId"
              value={formData.userId}
              readOnly
              className={styles.input}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="email">Email:</label>
          <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              readOnly
              className={styles.input}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="name_first">First Name:</label>
          <input
              type="text"
              id="name_first"
              name="name_first"
              value={formData.name_first}
              onChange={handleChange}
              required
              className={styles.input}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="name_last">Last Name:</label>
          <input
              type="text"
              id="name_last"
              name="name_last"
              value={formData.name_last}
              onChange={handleChange}
              required
              className={styles.input}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="gender">Gender:</label>
          <select
              id="gender"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              required
              className={styles.input}>
            <option value="">Select Gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="birthdate">Birthdate:</label>
          <input
              type="date"
              id="birthdate"
              name="birthdate"
              value={formData.birthdate}
              onChange={handleChange}
              required
              className={styles.input}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="allergies">Food and other allergies:</label>
          <textarea
              id="allergies"
              name="allergies"
              value={formData.allergies}
              onChange={handleChange}
              required
              className={styles.input}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="dietary_restrictions">Dietary Restrictions:</label>
          <textarea
              id="dietary_restrictions"
              name="dietary_restrictions"
              value={formData.dietary_restrictions}
              onChange={handleChange}
              required
              className={styles.input}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="home_church">Home Church/YA Group (Optional):</label>
          <input
              type="text"
              id="home_church"
              name="home_church"
              value={formData.home_church}
              onChange={handleChange}
              className={styles.input}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="emergency_contact_name">Emergency Contact Name:</label>
          <input
              type="text"
              id="emergency_contact_name"
              name="emergency_contact_name"
              value={formData.emergency_contact_name}
              onChange={handleChange}
              required
              className={styles.input}/>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="emergency_contact_phone">Emergency Contact Phone:</label>
          <input
              type="tel"
              id="emergency_contact_phone"
              name="emergency_contact_phone"
              value={formData.emergency_contact_phone}
              onChange={handleChange}
              placeholder="+11234567890"
              required
              className={styles.input}/>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="emergency_contact_relationship">Emergency Contact Relationship:</label>
          <input
              type="text"
              id="emergency_contact_relationship"
              name="emergency_contact_relationship"
              value={formData.emergency_contact_relationship}
              onChange={handleChange}
              required
              className={styles.input}/>
        </div>
        <div className={styles.formGroup}>
          <label>Photography consent:</label>
          <div className={styles.radioGroup}>
            <label className={styles.radioLabel}>
              <input
                  type="radio"
                  name="photography_consent"
                  value="yes"
                  checked={formData.photography_consent === 'yes'}
                  onChange={handleChange}
                  required
              />
              Yes, I give my consent.
            </label>
            <label className={styles.radioLabel}>
              <input
                  type="radio"
                  name="photography_consent"
                  value="no"
                  checked={formData.photography_consent === 'no'}
                  onChange={handleChange}
                  required
              />
              No, I do not give my consent.
            </label>
          </div>
          <p className={styles.consentExplanation}>
            {formData.photography_consent === 'yes'
                ? "I understand that my likeness may be used in social media, advertising campaigns, and other media. I also understand that I may revoke my consent at any time, and concerted efforts will be made to stop further use of my likeness after the revocation. However, this revocation will not affect any materials already published and/or distributed before the revocation, unless required so by law."
                : "I request that the staff and volunteers make concerted efforts to avoid capturing my image. I understand that if my image is captured in a way that conceals my identity (e.g., from behind or at an unidentifiable angle), it may still be used regardless of consent."}
          </p>
        </div>

        <div className={styles.checkboxGroup}>
          <label className={styles.checkboxLabel}>
            <input
                type="checkbox"
                name="drug_policy_acknowledged"
                checked={formData.drug_policy_acknowledged}
                onChange={handleChange}
                required
            />
            <span>
              I acknowledge that the use of alcohol, cannabis, and illicit substances is strictly prohibited at the Within The Veil Echo Lake Retreat. I understand that if I am found in possession of or under the influence of these substances, I will be asked to leave the premises immediately. Additionally, I agree that tobacco use is only permitted outside the property and will not smoke on the premises.
            </span>
          </label>
        </div>

        <div className={styles.checkboxGroup}>
          <label className={styles.checkboxLabel}>
            <input
                type="checkbox"
                name="medication_policy_acknowledged"
                checked={formData.medication_policy_acknowledged}
                onChange={handleChange}
                required
            />
            <span>
              I acknowledge that any medications I bring to the Within The Veil Echo Lake Retreat are my sole responsibility. I understand that the retreat staff and volunteers are not responsible for storing, administering, or monitoring my medications, and I agree to manage my medications independently.
            </span>
          </label>
        </div>

        <div className={styles.checkboxGroup}>
          <label className={styles.checkboxLabel}>
            <input
                type="checkbox"
                name="refund_policy_acknowledged"
                checked={formData.refund_policy_acknowledged}
                onChange={handleChange}
                required
            />
            <span>
              I acknowledge that starting at 11:59 PM on Tuesday, October 1st, all registrations for the Within The Veil Echo Lake Retreat become non-refundable. I understand that after this time, I will not be able to obtain a refund regardless of when I registered.
            </span>
          </label>
        </div>

        <div className={styles.checkboxGroup}>
          <label className={styles.checkboxLabel}>
            <input
                type="checkbox"
                name="truthfulness_acknowledged"
                checked={formData.truthfulness_acknowledged}
                onChange={handleChange}
                required
            />
            <span>
              I hereby certify that all the information I have provided in this form is true and accurate to the best of my knowledge. I understand that any false statements or misrepresentations may result in my being asked to leave the premises for the remainder of the Within The Veil Echo Lake Retreat, depending on the severity of the situation.
            </span>
          </label>
        </div>

        {submitError && <div className={styles.error}>{submitError}</div>}
        <button type="submit" disabled={isProcessing} className={styles.submitButton}>
          {isProcessing ? 'Processing...' : 'Register'}
        </button>
      </form>
    </div>
  );
}

export default Register;
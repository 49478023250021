// src/components/ConfirmSignUp.js
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { confirmSignUp } from 'aws-amplify/auth';
import './AuthForms.css';

function ConfirmSignUp() {
  const [code, setCode] = useState('');
  const [error, setError] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const username = location.state?.username;

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    try {
      const { isSignUpComplete } = await confirmSignUp({
        username,
        confirmationCode: code
      });

      if (isSignUpComplete) {
        console.log('Sign up confirmed successfully');
        navigate('/signin');
      }
    } catch (error) {
      console.error('Error confirming sign up:', error);
      setError(error.message);
    }
  };

  return (
    <div className="auth-form-container">
      <h2>Confirm Sign Up</h2>
      <form onSubmit={handleSubmit} className="auth-form">
        <div className="form-group">
          <label htmlFor="code">Confirmation Code</label>
          <input
            id="code"
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
          />
        </div>
        {error && <p className="error-message">{error}</p>}
        <button type="submit" className="submit-button">Confirm Sign Up</button>
      </form>
    </div>
  );
}

export default ConfirmSignUp;
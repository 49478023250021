import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useNavigate, useLocation } from 'react-router-dom';
import { processStripePayment} from '../graphql/mutations';
import styles from './Checkout.module.css';
import awsconfig from '../aws-exports';
import { v4 as uuidv4 } from 'uuid';

// Configure Amplify

const apiConfig = awsconfig.API.GraphQL;

function Checkout() {

  const location = useLocation();
  const { state } = location;

  console.debug('state:', state);

  const [formData] = useState({
    email: state.email,
  });
  const [paymentError, setPaymentError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  useEffect(() => {
    // const params = new URLSearchParams(location.search);

  }, [location, navigate]);

  // Calculate the amount. Early bird $75, regular $80 on and after September 25th
  const calculateAmount = () => {
    const currentDate = new Date();
    const cutoffDate = new Date('2024-09-25T00:00:00Z'); // Adjust year if needed

    if (currentDate < cutoffDate) {
      return 75;
    } else {
      return 80;
    }
  };

  const amount = calculateAmount();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsProcessing(true);

    if (!stripe || !elements) {
      console.error('Stripe.js has not loaded yet.');
      return;
    }

    try {
      const cardElement = elements.getElement(CardElement);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        console.error('Error creating payment method:', error);
        setPaymentError(error.message);
        setIsProcessing(false);
        return;
      }

      const payment_id = uuidv4();
      console.debug('payment_id:', payment_id);

      const inputData = {
        payment_id: payment_id,
        // customer_id: 'cus_QoRBjXkxdqjrSQ',
        customer_id: 'cus_QpFbQEPUdwRy9F',
        cognito_id: state.cognito_id,
        payment_method_id: paymentMethod.id,
        amount: amount,
        currency: 'cad',
        created_on: new Date().toISOString(),
      };

      const response = await fetch(apiConfig.endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiConfig.apiKey
        },
        body: JSON.stringify({
          query: processStripePayment,
          variables: { input: inputData }
        })
      });
      console.debug('response:', response);

      const status = response.status;
      if (status === 200) {
        console.info('Payment succeeded');
        navigate('/paymentConfirmation', {
          state: { registration_data: state, payment_data: { ...formData, transactionId: payment_id, paymentMethod: 'stripe', amount: amount } }
        });

      } else {
        throw new Error('Payment failed');
      }
    } catch (err) {
      console.error('Error:', err);
      setPaymentError(err.message);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className={styles.checkoutForm}>
      <h1>Registration Fee</h1>
      <h2>Within The Veil Retreat 2024</h2>
      <h3>October 25-27, 2024</h3>
      <form onSubmit={handleSubmit}>
        <h3>Within The Veil</h3>
        <div className={styles.formGroup}>
          <label htmlFor="email">Email:</label>
          <input
              type="text"
              id="email"
              name="email"
              value={formData.email}
              className={styles.input}
              readOnly
          />
        </div>

        <div className={styles.formGroup}>
          <label>Card Details:</label>
          <CardElement
              className={styles.cardElement}
              options={{
                country: 'CA',
                hidePostalCode: true,
              }}
          />
        </div>

        {paymentError && <div className={styles.error}>{paymentError}</div>}

        <button type="submit" disabled={isProcessing} className={styles.submitButton}>
          {isProcessing ? 'Processing...' : 'Pay $' + amount}
        </button>
      </form>
    </div>
  );
}

export default Checkout;
// Create a model to use in the frontend
// @ts-check


// We don't need to export the type definition in JavaScript

export const confirmRegistration = `
    mutation ConfirmRegistration($registration_data: RegistrationInput!, $payment_data: RegistrationPaymentInput!) {
        confirmRegistration(registration_data: $registration_data, payment_data: $payment_data) {
            status
            message
        }
    }
`;

export const processStripePayment = `
  mutation ProcessStripePayment($input: StripePaymentInput!) {
    processStripePayment(input: $input) {
      status
      message
    }
  }
`;

export const sendContactMessage = `
  mutation SendContactMessage($input: ContactMessageInput!) {
    sendContactMessage(input: $input) {
      success
      message
    }
  }
`;

export const processRegistration = `
  mutation ProcessRegistration($input: RegistrationInput!) {
    processRegistration(input: $input) {
      status
      registration_id
    }
  }
`;
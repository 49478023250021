import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Home.module.css';
import logo from '../assets/logo.jpg';
import elc from '../assets/elc.png';
import hrc from '../assets/hrc.png';

function Home() {
  return (
      <div className={styles.container}>
          <div className={styles.header}>
              <img src={logo} alt="Within The Veil Logo" className={styles.logo}/>
          </div>

          <h1 className={styles.title}>WTV Echo Lake Retreat 2024</h1>
          <p className={styles.contactText}>It is with a heavy heart that we must inform you of the cancellation of the Within The Veil Echo Lake Retreat due to unforeseen circumstances. Despite our best efforts, we were unable to reach the minimum number of participants required to move forward with the retreat.</p>
          <p className={styles.contactText}>As a result, we will not be proceeding with the event as planned. Your full refund has already been processed, and you should receive it in the coming days. If you encounter any issues regarding your refund, please don’t hesitate to contact us, and we will resolve it as quickly as possible.</p>
          <p className={styles.contactText}>God bless,<br/>
Within The Veil Leadership Team</p>

          <div className={styles.contactSection}>
              <h2 className={styles.contactTitle}>Contact Us</h2>
              <p className={styles.contactText}>Have questions? We're here to help!</p>
              <Link to="/contactUs" className={styles.link}>
                  <button className={styles.contactButton}>Get in touch</button>
              </Link>
          </div>

          <div className={styles.theme}>
              <h2>In partnership with</h2>
              <a href="https://www.echolakecamp.ca/"><img src={elc} alt="Echo Lake Camp" className={styles.campLogo}/></a>
              <a href="https://www.harbourridgecamp.com/"><img src={hrc} alt="Harbour Ridge Camp"  className={styles.campLogo}/></a>
              <h3>Our Socials</h3>
              <a href="https://www.instagram.com/wtvquinte/"><i className="fab fa-instagram"></i></a>&nbsp;
              <a href="https://www.facebook.com/WTVQuinte"><i className="fab fa-facebook"></i></a>&nbsp;
              <a href="https://linktr.ee/wtvquinte"><i className="fas fa-link"></i></a>&nbsp;
              <a href="mailto:wtvquinte@gmail.com"><i className="fas fa-envelope"></i></a>
              <br/>
              <h3>Hebrews 6:19 (NASB)</h3>
              <p>19 This hope we have as an anchor of the soul, a hope both sure and reliable and one which enters within the veil.</p>
          </div>
      </div>
  );
}

export default Home;